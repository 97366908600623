
// src/config.js

const config = {
    baseURL: process.env.REACT_APP_BASE_URL
};

export default config;


/*


${config.baseURL}


*/