import React from 'react'
import { Swiper,SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation, Pagination   } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {  Box, Typography } from '@mui/material';
import ProductCard from '../Shop/ProductCard';
import { FetchProducts } from '../../API/FetchProducts';
import ProductCardSi from '../Shop/Sinhala/ProductCardSi';






export const BestSales = ({isSinhalaTrue}) => {



    const {products, loading} = FetchProducts();


return(
    <Box margin='50px auto' width='90%'>
        
        {
            isSinhalaTrue? (<Typography variant='h5'>හොඳම විකුණුම් නිෂ්පාදන</Typography>):(<Typography variant='h5'>Best Sales</Typography>)
        }
<Swiper
                
                spaceBetween={50}
                autoplay={false}
                
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                speed={1200}
                // loop='auto'
                style={{
                    width: '100%',
                    '--swiper-navigation-color': '#0DFE0D',
                    '--swiper-pagination-color': '#0DFE0D',
                    
                }}
                breakpoints={{
                    0:{slidesPerView:1},
                    500:{slidesPerView:2},
                    900:{slidesPerView:3},
                    1200:{slidesPerView:4},
                
                }}
                >
                            {products.slice(0,9).map((product,i) => (
                                <SwiperSlide key={i} style={{padding:'25px 0px'}}>
                                    
                                    {
                                        isSinhalaTrue? (<ProductCardSi product={product}/>):(<ProductCard product={product} />)
                                    }
                                    
                                    
                                
                                </SwiperSlide>
                            ))}
            </Swiper>
            </Box>

)

}




export const HomeSwiper = ({imageArray}) => {
    return (
        <Swiper
                
                spaceBetween={0}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{ delay: 5000,}}
                pagination={{clickable: true,}}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                speed={3000}
                loop={true}
                style={{
                    width: '100%',
                    '--swiper-navigation-color': 'white',
                    '--swiper-pagination-color': 'white',
                    borderRadius:'0px',
                    minHeight:'100%'
                }}
                >

                {
                imageArray.map( (item, i) => (<SwiperSlide key={item.key}><img alt={item.key} src={item.images}  width='100%' height='100%'  style={{borderRadius:'0px',objectFit:'cover'}}/></SwiperSlide>) )
                }
            </Swiper>
    )
}

